import {
    DELETE_CART,
    UPDATE_CART,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CART_ACTION,
    CART_MODAL,
    CART_NOTIFY,
    OUT_OF_STOCK,
    LOGIN_MODAL,
    GET_CHARGES,
} from "./types";
import * as Sentry from "@sentry/react";

export const addToCart = (data, token) => (dispatch, getState) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });
        const branch = getState().branch.branchSelected;
        if (branch.isBranchSelected) {
            const cart = getState().cart.cart;
            const branchCart = cart.branchCart.filter(
                (el) => el.branchId === branch.branchId
            );
            if (branchCart.length > 0) {
                // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                // let cartData = {'city_id': data.branchId, 'site_product_id': data.product.id, 'quantity': data.quantity};

                // axios
                // .post("/api/user/cart",cartData)
                // .then(res => {
                //     dispatch({
                //         type: GET_CITIES,
                //         payload: {
                //             isLoading: false,
                //             isLoggedIn: true,
                //             data: res.data.data,
                //             accessToken: res.data.accessToken
                //         }
                //     });
                // })
                // .catch(err => {
                //     Sentry.withScope(scope => {
                //         scope.setExtra("title", "cartAction@addToCart");
                //         scope.setExtra("response", err.response);
                //         Sentry.captureException(err);
                //     });
                // });

                let productAlreadyInCart = false;
                if (branchCart[0].cartItems.length > 0) {
                    branchCart[0].cartItems.forEach((item) => {
                        if (item.productId === data.product.id) {
                            productAlreadyInCart = true;
                            item.quantity = parseFloat(data.quantity);
                            item.totalPrice = parseFloat(
                                data.product.price * data.quantity
                            );
                            item.price = data.product.price;
                            item.product.isBundle = data.isBundle;
                            item.product.bundleItemQty = data.bundleItemQty;
                        }
                    });
                    const subTotalAmount = branchCart[0].cartItems.reduce(
                        (a, c) => a + c.totalPrice,
                        0
                    );
                    const cartAmount = getCartAmount(
                        subTotalAmount,
                        branch.branch
                    );
                    branchCart[0].subTotalAmount = cartAmount.subTotalAmount;
                    branchCart[0].totalAmount = cartAmount.totalAmount;
                    branchCart[0].packingCharges = cartAmount.packingCharges;
                    branchCart[0].deliveryCharges = cartAmount.deliveryCharges;
                }

                if (!productAlreadyInCart) {
                    branchCart[0].cartItems.push({
                        productId: data.product.id,
                        product: data.product,
                        quantity: parseFloat(data.quantity),
                        totalPrice: parseFloat(
                            data.product.price * data.quantity
                        ),
                        price: data.product.price,
                        remarks: data.remarks,
                        priceChanged: false,
                        isBundle: data.isBundle,
                        bundleItemQty: data.bundleItemQty,
                    });
                    const subTotalAmount = branchCart[0].cartItems.reduce(
                        (a, c) => a + c.totalPrice,
                        0
                    );
                    const cartAmount = getCartAmount(
                        subTotalAmount,
                        branch.branch
                    );
                    branchCart[0].subTotalAmount = cartAmount.subTotalAmount;
                    branchCart[0].totalAmount = cartAmount.totalAmount;
                    branchCart[0].packingCharges = cartAmount.packingCharges;
                    branchCart[0].deliveryCharges = cartAmount.deliveryCharges;
                }

                dispatch({
                    type: ADD_TO_CART,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        message: "",
                        branchCart: cart.branchCart,
                    },
                });

                return dispatch({
                    type: CART_ACTION,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        message: data.product.nameEn + " added to cart",
                    },
                });
            } else {
                const subTotalAmount = parseFloat(
                    data.product.price * data.quantity
                );
                const cartAmount = getCartAmount(subTotalAmount, branch.branch);
                cart.branchCart.push({
                    branchId: data.branchId,
                    priceChanged: false,
                    productChanged: false,
                    message: "",
                    removedItems: [],
                    cartItems: [
                        {
                            productId: data.product.id,
                            product: data.product,
                            quantity: parseFloat(data.quantity),
                            totalPrice: parseFloat(
                                data.product.price * data.quantity
                            ),
                            price: data.product.price,
                            remarks: data.remarks,
                            priceChanged: false,
                        },
                    ],
                    subTotalAmount: cartAmount.subTotalAmount,
                    totalAmount: cartAmount.totalAmount,
                    packingCharges: cartAmount.packingCharges,
                    deliveryCharges: cartAmount.deliveryCharges,
                });
                dispatch({
                    type: ADD_TO_CART,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        message: "",
                        branchCart: cart.branchCart,
                    },
                });

                return dispatch({
                    type: CART_ACTION,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        message: data.product.nameEn + " added to cart",
                    },
                });
            }
        } else {
            Sentry.withScope((scope) => {
                scope.setExtra("title", "cartAction@addToCart");
                Sentry.captureException(
                    "failed to add item to cart due to branch id not selected"
                );
            });
            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: true,
                    message:
                        "Failed to add product to cart. please refresh page and try again.",
                },
            });
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@addToCart");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Failed to add product to cart.",
            },
        });
    }
};
export const storeCartItem = (data, token) => (dispatch, getState) => {
    try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const branch = getState().branch.branchSelected;
        const selectedBranch = branch.isBranchSelected
            ? branch.branch.deliveryLocations.filter(
                  (obj) =>
                      obj.area === getState().branch.branchSelected.branchArea
              )
            : 1;
        let sectorId = 0;
        if (selectedBranch != 1) sectorId = selectedBranch.map((obj) => obj.id);
        let cartData = {
            city_id: data.branchId,
            site_product_id: data.product.id,
            quantity: data.quantity,
            sector_id: sectorId[0],
        };
        axios
            .post("/api/user/cart", cartData)
            .then((res) => {})
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtra("title", "cartAction@storeCartItem");
                    scope.setExtra("response", err.response);
                    Sentry.captureException(err);
                });
            });
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@storeCartItem");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Failed to add product to cart.",
            },
        });
    }
};

export const removeFromCart = (productId) => (dispatch, getState) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });

        let token = getState().auth.user.accessToken;
        const branch = getState().branch.branchSelected;
        const selectedBranch = branch.isBranchSelected
            ? branch.branch.deliveryLocations.filter(
                  (obj) => obj.area === branch.branchArea
              )
            : 1;
        let sectorId = 0;
        if (selectedBranch != 1) sectorId = selectedBranch.map((obj) => obj.id);

        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            axios
                .post(`/api/user/cart/remove-item?sector_id=${sectorId[0]}`, {
                    product_id: productId,
                })
                .then((res) => {})
                .catch((err) => {});
        }
        if (branch.isBranchSelected) {
            const cart = getState().cart.cart;
            const branchCart = cart.branchCart.filter(
                (x) => x.branchId === branch.branchId
            );

            if (branchCart[0].cartItems.length > 0) {
                const items = branchCart[0].cartItems.filter(
                    (elm) => elm.productId !== productId
                );
                branchCart[0].cartItems = items;

                const subTotalAmount = branchCart[0].cartItems.reduce(
                    (a, c) => a + c.totalPrice,
                    0
                );
                const cartAmount = getCartAmount(subTotalAmount, branch.branch);
                branchCart[0].subTotalAmount = cartAmount.subTotalAmount;
                branchCart[0].totalAmount = cartAmount.totalAmount;
                branchCart[0].packingCharges = cartAmount.packingCharges;
                branchCart[0].deliveryCharges = cartAmount.deliveryCharges;
            }

            dispatch({
                type: REMOVE_FROM_CART,
                payload: {
                    isLoading: false,
                    hasError: false,
                    message: "",
                    branchCart: branchCart,
                },
            });

            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: false,
                    message: "Product successfully removed from cart.",
                },
            });
        } else {
            Sentry.withScope((scope) => {
                scope.setExtra("title", "cartAction@addToCart");
                Sentry.captureException(
                    "failed to remove item to cart due to branch id not selected"
                );
            });
            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: true,
                    message:
                        "Failed to remove product to cart. please refresh page and try again.",
                },
            });
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@removeFromCart");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Failed to remove product from cart.",
            },
        });
    }
};
export const getCharges = (amount) => (dispatch, getState) => {
    dispatch({
        type: GET_CHARGES,
        payload: {
            isLoading: true,
            hasError: false,
            message: "",
            data: {},
        },
    });
    let token = getState().auth.user.accessToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
        .get(`/api/user/delivery?amount=${amount}`)
        .then((res) => {
            dispatch({
                type: GET_CHARGES,
                payload: {
                    isLoading: false,
                    hasError: false,
                    message: res.data.message,
                    data: res.data.data,
                },
            });
        })
        .catch((err) => {
            if (err.response.data.statusCode == 401) {
                dispatch({
                    type: SIGN_OUT,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        isLoggedIn: false,
                        data: {},
                        message: "",
                        accessToken: null,
                    },
                });
                dispatch({
                    type: SIGN_IN,
                    payload: {
                        isLoading: false,
                        hasError: true,
                        data: err.response.data,
                        message: err.response.data.message,
                    },
                });
            } else {
                dispatch({
                    type: GET_CHARGES,
                    payload: {
                        isLoading: false,
                        hasError: true,
                        message: "Something went wrong, please try again later",
                        data: {},
                    },
                });
            }
        });
};

export const updateCart = (data) => (dispatch, getState) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });
        const branch = getState().branch.branchSelected;
        if (branch.isBranchSelected) {
            const cart = getState().cart.cart;
            const branchCart = cart.branchCart.filter(
                (x) => x.branchId === branch.branchId
            );
            if (branchCart[0].cartItems.length > 0) {
                branchCart[0].cartItems.forEach((item) => {
                    if (item.productId === parseInt(data.product.id)) {
                        item.quantity = parseFloat(data.quantity);
                        item.totalPrice = parseFloat(
                            data.product.price * data.quantity
                        );
                        item.remarks = data.remarks;
                        item.priceChanged = false;
                    }
                });
                branchCart[0].priceChanged = false;
                branchCart[0].productChanged = false;
                const subTotalAmount = branchCart[0].cartItems.reduce(
                    (a, c) => a + c.totalPrice,
                    0
                );
                const cartAmount = getCartAmount(subTotalAmount, branch.branch);
                branchCart[0].subTotalAmount = cartAmount.subTotalAmount;
                branchCart[0].totalAmount = cartAmount.totalAmount;
                branchCart[0].packingCharges = cartAmount.packingCharges;
                branchCart[0].deliveryCharges = cartAmount.deliveryCharges;
            }

            dispatch({
                type: UPDATE_CART,
                payload: {
                    isLoading: false,
                    hasError: false,
                    branchCart: branchCart,
                },
            });
            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: false,
                    message: "Product successfully update.",
                },
            });
        } else {
            Sentry.withScope((scope) => {
                scope.setExtra("title", "cartAction@updateCart");
                Sentry.captureException(
                    "failed to remove item to cart due to branch id not selected"
                );
            });
            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: true,
                    message:
                        "Failed to remove product to cart. please refresh page and try again.",
                },
            });
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartActions@updateCart");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Failed to update product in cart.",
            },
        });
    }
};

export const deleteCart = () => (dispatch, getState) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });
        const branch = getState().branch.branchSelected;
        if (branch.isBranchSelected) {
            const cart = getState().cart.cart;
            const branchCart = cart.branchCart.filter(
                (x) => x.branchId !== branch.branchId
            );

            dispatch({
                type: DELETE_CART,
                payload: {
                    isLoading: false,
                    hasError: false,
                    message: "",
                    // branchCart: branchCart
                    branchCart: [],
                },
            });

            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: false,
                    message: "Product successfully removed from cart.",
                },
            });
        } else {
            Sentry.withScope((scope) => {
                scope.setExtra("title", "cartAction@addToCart");
                Sentry.captureException(
                    "failed to remove item to cart due to branch id not selected"
                );
            });
            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: true,
                    message:
                        "Failed to remove product to cart. please refresh page and try again.",
                },
            });
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@removeFromCart");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Failed to remove product from cart.",
            },
        });
    }
};

export const resetServerCart = () => (dispatch, getState) => {
    console.log("reset cartcalled");
    let token = getState().auth.user.accessToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
        .post("/api/user/reset/cart", {})
        .then((res) => {
            console.log("cart cleared");
        })
        .catch((err) => {
            console.log("Something went wrong on cart reset");
        });
};

export const clearCart = () => (dispatch, getState) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });
        const branch = getState().branch.branchSelected;
        if (branch.isBranchSelected) {
            const cart = getState().cart.cart;
            const branchCart = cart.branchCart.filter(
                // x => x.branchId !== branch.branchId
                (x) => x.branchId !== 60000
            );

            dispatch({
                type: DELETE_CART,
                payload: {
                    isLoading: false,
                    hasError: false,
                    message: "",
                    // branchCart: branchCart
                    branchCart: [],
                },
            });
            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: false,
                },
            });
        } else {
            Sentry.withScope((scope) => {
                scope.setExtra("title", "cartAction@addToCart");
                Sentry.captureException(
                    "failed to remove item to cart due to branch id not selected"
                );
            });
            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: true,
                    message:
                        "Failed to remove product to cart. please refresh page and try again.",
                },
            });
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@removeFromCart");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Failed to remove product from cart.",
            },
        });
    }
};

export const showCartModal = (status, product) => (dispatch) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });
        if (status) {
            return dispatch({
                type: CART_MODAL,
                payload: {
                    status: status,
                    product: product,
                },
            });
        } else {
            return dispatch({
                type: CART_MODAL,
                payload: {
                    status: status,
                    product: "",
                },
            });
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@showCartModal");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong, please try again",
            },
        });
    }
};
export const showLoginModal = (status, product) => (dispatch) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });
        if (status) {
            return dispatch({
                type: LOGIN_MODAL,
                payload: {
                    status: status,
                    product: product,
                },
            });
        } else {
            return dispatch({
                type: LOGIN_MODAL,
                payload: {
                    status: status,
                    product: "",
                },
            });
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@showLoginModal");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong, please try again",
            },
        });
    }
};
export const outOfStock = (product) => (dispatch) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });

        if (product) {
            let data = {
                product_id: product.product.id,
                qty: product.quantity,
                branch: product.branchId,
            };
            axios.defaults.headers.post["verification"] =
                "pZz7&9Wv5qQBcUmbCvpLuZ%m2WScQqK!hkr6_8OZiADIlV_gNR6m4PSOpX8L4VJIlxNCV0g2yXUksNmn3lLnOc91fCLVHnr31H0Ofu14GByuhEtmxTwUx7VGT5FpNXv1hCMD9127";
            axios.post("/api/user/order/stock", data);
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@showCartModal");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong, please try again",
            },
        });
    }
};

export const reorderToCart = (data) => (dispatch, getState) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });
        const branch = getState().branch.branchSelected;
        if (branch.isBranchSelected) {
            const cart = getState().cart.cart;
            const branchCart = cart.branchCart.filter(
                (el) => el.branchId === branch.branchId
            );
            if (branchCart.length > 0) {
                let productAlreadyInCart = false;
                if (branchCart[0].cartItems.length > 0) {
                    branchCart[0].cartItems.forEach((item) => {
                        if (item.productId === data.product.id) {
                            productAlreadyInCart = true;
                            item.quantity = parseFloat(data.quantity);
                            item.totalPrice = parseFloat(
                                data.product.price * data.quantity
                            );
                            item.price = data.product.price;
                        }
                    });
                    const subTotalAmount = branchCart[0].cartItems.reduce(
                        (a, c) => a + c.totalPrice,
                        0
                    );
                    const cartAmount = getCartAmount(
                        subTotalAmount,
                        branch.branch
                    );
                    branchCart[0].subTotalAmount = cartAmount.subTotalAmount;
                    branchCart[0].totalAmount = cartAmount.totalAmount;
                    branchCart[0].packingCharges = cartAmount.packingCharges;
                    branchCart[0].deliveryCharges = cartAmount.deliveryCharges;
                }

                if (!productAlreadyInCart) {
                    branchCart[0].cartItems.push({
                        productId: data.product.id,
                        product: data.product,
                        quantity: parseFloat(data.quantity),
                        totalPrice: parseFloat(
                            data.product.price * data.quantity
                        ),
                        price: data.product.price,
                        remarks: data.remarks,
                        priceChanged: false,
                    });
                    const subTotalAmount = branchCart[0].cartItems.reduce(
                        (a, c) => a + c.totalPrice,
                        0
                    );
                    const cartAmount = getCartAmount(
                        subTotalAmount,
                        branch.branch
                    );
                    branchCart[0].subTotalAmount = cartAmount.subTotalAmount;
                    branchCart[0].totalAmount = cartAmount.totalAmount;
                    branchCart[0].packingCharges = cartAmount.packingCharges;
                    branchCart[0].deliveryCharges = cartAmount.deliveryCharges;
                }

                dispatch({
                    type: ADD_TO_CART,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        message: "",
                        branchCart: cart.branchCart,
                    },
                });

                return dispatch({
                    type: CART_ACTION,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        message: data.product.nameEn + " added to cart",
                    },
                });
            } else {
                const subTotalAmount = parseFloat(
                    data.product.price * data.quantity
                );
                const cartAmount = getCartAmount(subTotalAmount, branch.branch);
                cart.branchCart.push({
                    branchId: data.branchId,
                    priceChanged: false,
                    productChanged: false,
                    message: "",
                    removedItems: [],
                    cartItems: [
                        {
                            productId: data.product.id,
                            product: data.product,
                            quantity: parseFloat(data.quantity),
                            totalPrice: parseFloat(
                                data.product.price * data.quantity
                            ),
                            price: data.product.price,
                            remarks: data.remarks,
                            priceChanged: false,
                        },
                    ],
                    subTotalAmount: cartAmount.subTotalAmount,
                    totalAmount: cartAmount.totalAmount,
                    packingCharges: cartAmount.packingCharges,
                    deliveryCharges: cartAmount.deliveryCharges,
                });
                dispatch({
                    type: ADD_TO_CART,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        message: "",
                        branchCart: cart.branchCart,
                    },
                });

                return dispatch({
                    type: CART_ACTION,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        message: data.product.nameEn + " added to cart",
                    },
                });
            }
        } else {
            Sentry.withScope((scope) => {
                scope.setExtra("title", "cartAction@addToCart");
                Sentry.captureException(
                    "failed to add item to cart due to branch id not selected"
                );
            });
            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: true,
                    message:
                        "Failed to add product to cart. please refresh page and try again.",
                },
            });
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@addToCart");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Failed to add product to cart.",
            },
        });
    }
};

export const cartNotify = (bol) => (dispatch, getState) => {
    if (!bol) {
        const branch = getState().branch.branchSelected;
        if (branch.isBranchSelected) {
            const cart = getState().cart.cart;
            const branchCart = cart.branchCart.filter(
                (x) => x.branchId === branch.branchId
            );

            if (branchCart[0]) {
                branchCart[0].priceChanged = false;
                branchCart[0].productChanged = false;
            }
        }
        sessionStorage.setItem("cartNotify", false);
    }
    return dispatch({
        type: CART_NOTIFY,
        payload: {
            isLoading: false,
            status: bol,
        },
    });
};

export const updatedLocalCart = (selectedSectorId) => (dispatch, getState) => {
    try {
        dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: true,
                hasError: false,
                message: "",
            },
        });
        const branch = getState().branch.branchSelected;
        if (branch.isBranchSelected) {
            const cart = getState().cart.cart;
            const branchCart = cart.branchCart.filter(
                (el) => el.branchId === branch.branchId
            );
            if (branchCart.length > 0) {
                // alert('stop');
                if (branchCart[0].cartItems.length > 0) {
                    const auth = getState().auth.user;
                    const branchId = branch.isBranchSelected
                        ? branch.branch.deliveryLocations.filter(
                              (obj) => obj.area === branch.branchArea
                          )
                        : 1;
                    const cityId = branchId.map((obj) => obj.cityId);

                    //NEED TO CHECK THIS CODE HERE
                    let sectorId = "";
                    if (selectedSectorId) sectorId = selectedSectorId;
                    else sectorId = branchId.map((obj) => obj.id);

                    // const branchId = branch.isBranchSelected
                    //     ? branch.branch.id
                    //     : 1;
                    const userType = auth.isLoggedIn ? auth.data.type : 1;
                    const data = {
                        cityId: cityId[0],
                        userType: userType,
                        products: branchCart[0].cartItems,
                        sectorId: sectorId[0] ? sectorId[0] : sectorId,
                    };
                    axios
                        .post("/api/cart/verify/web", data)
                        .then((res) => {
                            branchCart[0].removedItems = res.data.data
                                .slice()
                                .filter((elm) => elm.product.isActive === 0);
                            const activeCartProducts = res.data.data
                                .slice()
                                .filter((elm) => elm.product.isActive !== 2);

                            activeCartProducts.forEach((item) => {
                                branchCart[0].cartItems.forEach((product) => {
                                    if (product.productId == item.productId) {
                                        if (
                                            parseInt(product.product.price) !==
                                            parseInt(item.product.price)
                                        ) {
                                            product.priceChanged = true;
                                            branchCart[0].priceChanged = true;
                                            product.product = item.product;
                                            product.totalPrice =
                                                parseFloat(
                                                    product.product.price
                                                ) *
                                                parseFloat(product.quantity);
                                            product.price = item.product.price;
                                            sessionStorage.setItem(
                                                "cartNotify",
                                                true
                                            );
                                        } else {
                                            product.priceChanged = false;
                                            product.product = item.product;
                                        }

                                        if (item.product.isActive == 0) {
                                            product.productChanged = true;
                                            branchCart[0].productChanged = true;
                                            sessionStorage.setItem(
                                                "cartNotify",
                                                true
                                            );
                                        }
                                    }
                                });
                            });
                            const subTotalAmount =
                                branchCart[0].cartItems.reduce(
                                    (a, c) => a + c.totalPrice,
                                    0
                                );
                            const cartAmount = getCartAmount(
                                subTotalAmount,
                                branch.branch
                            );
                            branchCart[0].subTotalAmount =
                                cartAmount.subTotalAmount;
                            branchCart[0].totalAmount = cartAmount.totalAmount;
                            branchCart[0].packingCharges =
                                cartAmount.packingCharges;
                            branchCart[0].deliveryCharges =
                                cartAmount.deliveryCharges;

                            // if (branchCart[0].removedItems.length > 0) {
                            //     branchCart[0].productChanged = true;
                            //     // cart.productChanged = true;
                            //     sessionStorage.setItem(
                            //         "cartNotify",
                            //         true
                            //     );
                            // }

                            return dispatch({
                                type: UPDATE_CART,
                                payload: {
                                    isLoading: false,
                                    hasError: false,
                                    message: "",
                                    branchCart: cart.branchCart,
                                },
                            });
                        })
                        .catch((err) => {
                            Sentry.withScope((scope) => {
                                scope.setExtra(
                                    "title",
                                    "cartAction@updatedLocalCart"
                                );
                                scope.setExtra("response", err.response);
                                Sentry.captureException(err);
                            });
                            return dispatch({
                                type: CART_ACTION,
                                payload: {
                                    isLoading: false,
                                    hasError: true,
                                    message:
                                        "Something went wrong. please try again.",
                                },
                            });
                        });
                }
            }
        } else {
            Sentry.withScope((scope) => {
                scope.setExtra("title", "cartAction@addToCart");
                Sentry.captureException(
                    "failed to add item to cart due to branch id not selected"
                );
            });
            return dispatch({
                type: CART_ACTION,
                payload: {
                    isLoading: false,
                    hasError: true,
                    message:
                        "Failed to add product to cart. please refresh page and try again.",
                },
            });
        }
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("title", "cartAction@updatedLocalCart");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        return dispatch({
            type: CART_ACTION,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong. please try again.",
            },
        });
    }
};

const getCartAmount = (subTotalAmount, branch) => {
    if (subTotalAmount >= parseInt(branch.freeDeliveryLimit)) {
        if (branch.packingChargesStatus == 1) {
            return {
                subTotalAmount: subTotalAmount,
                totalAmount: subTotalAmount + parseInt(branch.packingCharges),
                packingCharges: branch.packingCharges,
                deliveryCharges: 0,
            };
        } else {
            return {
                subTotalAmount: subTotalAmount,
                totalAmount: subTotalAmount,
                packingCharges: 0,
                deliveryCharges: 0,
            };
        }
    } else {
        if (branch.packingChargesStatus == 1) {
            return {
                subTotalAmount: subTotalAmount,
                totalAmount:
                    subTotalAmount +
                    parseInt(branch.packingCharges) +
                    parseInt(branch.deliveryCharges),
                packingCharges: parseInt(branch.packingCharges),
                deliveryCharges: parseInt(branch.deliveryCharges),
            };
        } else {
            return {
                subTotalAmount: subTotalAmount,
                totalAmount: subTotalAmount + parseInt(branch.deliveryCharges),
                packingCharges: 0,
                deliveryCharges: parseInt(branch.deliveryCharges),
            };
        }
    }
};
